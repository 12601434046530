import Layout from "../components/layout"
import Hero from "../components/hero"
import React from "react"
import Reviews from "../components/reviews"
import { useStaticQuery, graphql, Link } from "gatsby"
import SEO from "../components/seo"
import OptInForm from "../components/optInForm"
import * as ServicePageStyles from "../styles/servicepage.module.scss"
import PortfolioCarousel from "../components/portfolioCarousel"
import GoogleReviewCarousel from "../components/google-review-carousel"
import TwentyTwenty from "react-twentytwenty"
import * as SliderStyles from "../styles/slider.module.scss"

const InteriorPaintingPage = () => {
  const data = useStaticQuery(graphql`
    query InteriorQuery {
      bannerImage: cloudinaryMedia(
        public_id: {
          eq: "pivot-painters-chicago/portfolio/pivot-painters-1107-north-sacramento-chicago-13"
        }
      ) {
        secure_url
      }
      portfolioImg1: cloudinaryMedia(
        public_id: {
          eq: "pivot-painters-chicago/portfolio/pivot-painters-900-north-richmond-street-chicago-18"
        }
      ) {
        secure_url
      }
      portfolioImg2: cloudinaryMedia(
        public_id: {
          eq: "pivot-painters-chicago/portfolio/pivot-painters-1430-west-cuyler-avenue-chicago-3"
        }
      ) {
        secure_url
      }
      portfolioImg3: cloudinaryMedia(
        public_id: {
          eq: "pivot-painters-chicago/portfolio/pivot-painters-2328-north-rockwell-street-chicago-2"
        }
      ) {
        secure_url
      }
      portfolioImg4: cloudinaryMedia(
        public_id: {
          eq: "pivot-painters-chicago/portfolio/pivot-painters-5858-north-broadway-street-chicago-2"
        }
      ) {
        secure_url
      }
      bmIcon: cloudinaryMedia(public_id: { eq: "pivot-painters-chicago/icons/bm" }) {
        secure_url
      }
      swIcon: cloudinaryMedia(
        public_id: { eq: "pivot-painters-chicago/icons/sherwin-williams" }
      ) {
        secure_url
      }
      googleIcon: cloudinaryMedia(
        public_id: { eq: "pivot-painters-chicago/icons/5-Google-Review-Badge" }
      ) {
        secure_url
      }
      angiIcon: cloudinaryMedia(
        public_id: { eq: "pivot-painters-chicago/icons/toprated-solid-border" }
      ) {
        secure_url
      }
      imageRevealLeft: cloudinaryMedia(
        public_id: {
          eq: "pivot-painters-chicago/pivot-painters-1515-west-chestnut-street-chicago-1"
        }
      ) {
        secure_url
      }
      imageRevealRight: cloudinaryMedia(
        public_id: {
          eq: "pivot-painters-chicago/portfolio/pivot-painters-1515-west-chestnut-street-chicago-2"
        }
      ) {
        secure_url
      }
    }
  `)
  return (
    <Layout>
      <SEO
        title="Interior Painting Chicago | Pivot Painters Chicago"
        description="Interior Painting Chicago is one of the best ways to transform the look of your home. Experience a new home with Interior Painting. Schedule a free estimate!"
      />
      <Hero
        name="Interior Painting Chicago"
        src={data.bannerImage.secure_url}
        subtitle="Transform Your Home"
      />
      <div className={ServicePageStyles.servicePageContainer}>
        <section className={ServicePageStyles.trustBadges}>
          <img
            src={data.bmIcon.secure_url}
            alt="Pivot Painters Chicago - Benjamin Moore Icon"
          />
          <img
            src={data.swIcon.secure_url}
            alt="Pivot Painters Chicago - Sherwin Williams Icon"
          />
          <img
            src={data.angiIcon.secure_url}
            alt="Pivot Painters Chicago - Angi Leads Icon"
          />
          <img
            src={data.googleIcon.secure_url}
            alt="Pivot Painters Chicago - Google Icon"
          />
        </section>

        <div className={ServicePageStyles.flexContainer}>
          <PortfolioCarousel
            captions="Interior Painting Chicago - Pivot Painters Chicago"
            images={[
              data.portfolioImg1.secure_url,
              data.portfolioImg2.secure_url,
              data.portfolioImg3.secure_url,
              data.portfolioImg4.secure_url,
            ]}
          />
          <div className={ServicePageStyles.textContent}>
            <h2 className={ServicePageStyles.textContentHeader}>
              Interior Painting <span className="heavyLato">Done Right.</span>
            </h2>
            Pivot Painters{" "}
            <strong>makes your house feel more like a home</strong> through our
            Chicago interior painting service. Our professional painters are
            freaky, friendly perfectionists who pay attention to all the details
            and take pride in their work.
            <br></br>
            <br></br>
            Interior painting is a great way to give your home a new look as
            color and finish are two very powerful traits that can define a
            home's character by themselves.
            <br></br>
            <br></br> Whether you are looking to change the colors and feels of
            your home, are moving in or out, or manage a property that needs to
            be repainted, Pivot Painters Chicago is eager and happy to help
            provide the{" "}
            <strong>best interior painting Chicago has to offer!</strong>
            <Link className={ServicePageStyles.ctaButton} to="/request-a-quote">
              Schedule A Free Estimate
            </Link>
          </div>
        </div>
        <div className={ServicePageStyles.reviewsContainer}>
          <h2 className={ServicePageStyles.textContentHeader}>
            What Past Customers Are Saying
          </h2>
          <GoogleReviewCarousel />
        </div>

        <div className={ServicePageStyles.textContent}>
          <h2 className={ServicePageStyles.textContentHeader}>
            Why Hire Pivot Painters?
          </h2>
          Interior Painting may look easy on the outside but in order to be left
          with a <strong>high-quality, seamless finish</strong>, you either need
          to hire a professional or have a weirdly high natural talent of
          precision and hand-eye coordination. Painting incorrectly can result
          in wavy lines, patchy spots, peeling, and even damage to your home.
          <br></br>
          <br></br>With Pivot Painters Chicago, you don't have to worry about
          any of that as our professional interior painting crews use the best
          quality materials and <strong>industry-leading techniques</strong> to
          prevent those issues from happening.
          <br></br>
          <br></br>
          Being comfortable and{" "}
          <strong>enjoying the feeling and look of your home</strong> is a
          beautiful thing! Color is a very powerful instrument for conveying
          emotions and adding to the ultimate appearance of your home which is
          why interior painting can make such a huge difference.<br></br>
          <br></br>Our interior painting Chicago crews understand this and are{" "}
          <strong>dedicated to deliver an outstanding experience</strong> and
          help redefine the look of your home the way you want it!
          <ul>
            <li>
              <i className="fas fa-brush"></i>
              <p>Trusted And Experienced Professionals</p>
            </li>
            <li>
              <i className="fas fa-brush"></i>
              <p>Use Premium Quality Materials</p>
            </li>
            <li>
              <i className="fas fa-brush"></i>
              <p>Constant And Reliable Communication</p>
            </li>
            <li>
              <i className="fas fa-brush"></i>
              <p>Lifetime Touchup Warranty</p>
            </li>
          </ul>
          <TwentyTwenty
            left={
              <img
                src={data.imageRevealLeft.secure_url}
                className={SliderStyles.sliderImage}
              />
            }
            right={
              <img
                src={data.imageRevealRight.secure_url}
                className={SliderStyles.sliderImage}
              />
            }
            slider={
              <div className={SliderStyles.slider}>
                <div className={SliderStyles.sliderIcon}>
                  <i className="fas fa-arrows-alt-h"></i>
                </div>
              </div>
            }
          />
          <div>
            <h2 className={ServicePageStyles.textContentHeader}>
              Advantages of Interior Painting
            </h2>
            <ul>
              <li>
                <i className="fas fa-brush"></i>
                <p>
                  <strong>Refreshes Your Home</strong> Repainting your walls,
                  ceiling, or baseboards can modernize an outdated color scheme,
                  brighten up your rooms, and give the interior of your home a
                  different character.
                </p>
              </li>
              <li>
                <i className="fas fa-brush"></i>
                <p>
                  <strong>Cost Effective</strong> Updating the look of your home
                  doesn't need to cost a fortune. Chicago interior paint
                  services can be a much cheaper way of transforming it's
                  appearance and feel.
                </p>
              </li>
              <li>
                <i className="fas fa-brush"></i>
                <p>
                  <strong>Protects Your Home</strong> Our Chicago Interior
                  Painting crews use high-quality paints and coatings that are
                  designed to help protect your walls, baseboards, and ceilings
                  from wear and tear.
                </p>
              </li>
              <li>
                <i className="fas fa-brush"></i>
                <p>
                  <strong>Increases Property Value</strong> Interior Painting is
                  one of the best and most cost-effective ways of increasing the
                  property value of your house or condo and can make it look
                  much more appealing to potential buyers.
                </p>
              </li>
            </ul>
            <p>
              Want to know more about our interior painting Chicago services?{" "}
              <a href="tel:3128549180‬">
                <strong>Call (312) 854-9180‬ today!</strong>
              </a>
            </p>
          </div>
          <OptInForm></OptInForm>
        </div>

        <Reviews></Reviews>
      </div>
    </Layout>
  )
}

export default InteriorPaintingPage
